import React, { Fragment, useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Footer from './Footer'
import '../../stylesheets/main.scss'

export default props => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={ data => <Layout data={ data } { ...props }/> }
  />
)

const Layout = (props) => {
  // Define the meta title and description
  const title = props.data.site.siteMetadata.title
  const description = props.data.site.siteMetadata.description;

  // Load the Prismic edit button
  if(typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton()
  }

  const [active, setActive] = useState('');
  const [navActive, setNavActive] = useState(false);

  // const scrollFunc = () => {
  //   const offset = 50;
  //   const whoWeAre = document.getElementById('whoweare').offsetTop;
  //   const join = document.getElementById('join').offsetTop;
  //   // const who = document.getElementById('who') ? document.getElementById('who').offsetTop : 0;
  //   const faq = document.getElementById('faq').offsetTop;
  //   // const work = document.getElementById('work').offsetTop;
  //   const contact = document.getElementById('contact').offsetTop;
  //   const windowTop = window.pageYOffset;

  //   if (windowTop < whoWeAre && active !== 'home') setActive('home');
  //   if (windowTop >= whoWeAre - offset && windowTop < join && active !== 'about') setActive('about');
  //   if (windowTop >= join - offset && windowTop < faq && active !== 'join') setActive('join');
  //   if (windowTop >= faq - offset && windowTop < contact && active !== 'faq') setActive('faq');
  //   if (windowTop >= contact - (window.innerHeight / 2) && active !== 'contact') setActive('contact'); 
  // }
  
  // useEffect(() => {
  //   scrollFunc();
  //   window.addEventListener('scroll', scrollFunc);
  //   return () => window.removeEventListener('scroll', scrollFunc);
  // }, []);

	return(
    <Fragment>
      <Helmet
        bodyAttributes={{
          class: navActive ? 'nav-active' : ''
      }}>
        <meta charSet="utf-8" />
        <title>{ title }</title>
        <meta name="description" content={ description } />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
      </Helmet>
      <nav>
        <ul>
          {props.sections && props.sections.length && props.sections.map((section) => {
            const navTitle = section.primary.navigation_link_title || null;
            const url = navTitle ? navTitle.toLowerCase().replace(/\s+/g, '-') : null;
            if (section.type === "hero_slice") {
              return <AnchorLink offset='0' href="#home" onClick={() => setNavActive(false)}><li className={active === "home" ? 'active' : ''}>{navTitle}</li></AnchorLink>
            }

            return (
              <AnchorLink offset='0' href={`#${url}` || "#"} onClick={() => setNavActive(false)}><li className={active === url ? 'active' : ''}>{navTitle}</li></AnchorLink>
            )
          })}
        </ul>
      </nav>
      <button className="hamburger" aria-label="Main Menu Button" onClick={() => setNavActive(!navActive)}>
        <div className="patties">
          <span></span>
          <span></span>
          <span></span>
          <span></span>    
        </div>
      </button>
      <main>
        { props.children }
      </main>
      <Footer/>
    </Fragment>
	)
}